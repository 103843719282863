<template>
    <div class="_conetntList">
        <div class="_li" v-for="(item, index) in list" :key="index" @click="goDetail(item, '实景党课')">
            <div class="_left">
                <img :src="$IMG_URL + item.cover" alt="八路军驻洛办事处纪念馆-官方网站" class="_leftImg">
            </div>
            <div class="_right">
                <h4>{{ item.title }}</h4>
                <div class="_cont">
                    <p class="_c_p">
                        {{ item.introduction }}
                    </p>
                    <div class="_time">
                        <span class="_impact">{{ item.release_date.substring(8, 10) }}</span>
                        <p class="_impact">{{ item.release_date.substring(0, 7) }}</p>
                    </div>

                </div>
                <div class="_l_b">

                    <div class="_ll">
                        <p>
                            <img src="@/assets/image/information-img/yj.png" alt="八路军驻洛办事处纪念馆-官方网站">
                            <span class="_impact">浏览量：{{ item.hits }}</span>
                        </p>
                        <img src="@/assets/image/information-img/news_icon_details.png" alt="八路军驻洛办事处纪念馆-官方网站">
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { hits } from '@/api/getData'
export default {
    props: {
        list: {
            type: Array
        }
    },
    data() {
        return {

        }
    },
    methods: {
        goDetail(item, type) {
            if (item.link != null && item.link != '') {
                const params = {
                    type: 4,
                    id: item.id
                }
                hits(params)
                window.open(item.link)
            } else {
                let url = location.origin
                window.open(`${url}/educationDetail?id=${item.id}&breadName=${type}`)
            }


        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../../common/mixin.scss";

._conetntList
{
    // margin-top: 50px;

    ._li
    {
        display: flex;
        align-items: center;
        padding: 20px 0;
        border-bottom: 1px solid #eee;
        cursor: pointer;

        ._left
        {
            margin-right: 20px;

            img
            {
                width: 280px;
                height: 182px;
                object-fit: cover;
            }
        }

        ._right:hover>h4
        {
            color: $hover-color;
        }

        ._right
        {
            h4
            {
                color: #333;
                font-weight: bold;
                font-size: 24px;
                letter-spacing: 1px;
            }

            ._cont
            {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 10px 0 32px;


                ._c_p
                {
                    color: #999;
                    letter-spacing: 1px;
                    width: 70%;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    /* 设置最大显示行数 */
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    font-size: 20px;
                }

                ._time
                {
                    text-align: center;

                    span
                    {
                        color: #B41E1E;
                        font-size: 48px;

                    }

                    p
                    {
                        letter-spacing: 1px;
                        color: #333;
                        font-size: 18px;
                    }
                }
            }

            ._l_b
            {

                ._ll
                {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    p
                    {

                        img
                        {
                            width: 21px;
                            vertical-align: middle;


                        }

                        span
                        {
                            color: #999;
                            margin-left: 10px;
                            letter-spacing: 1px;
                            font-size: 18px;
                        }
                    }

                    img
                    {
                        width: 51px;
                    }
                }
            }
        }
    }
}


@include respondTo('phone'){
    ._conetntList{
        ._li{

            ._left{
                width: 80px;
                height: 80px;
                margin-right: 0;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            ._right{

                width: 70%;
                margin-left: 20px;
                h4{
                    font-size: 18px;
                    width: 100%;
                }

                ._cont{
                    display: block;
                    margin: 5px 0;
                    ._c_p{
                    -webkit-line-clamp: 1;
                        width: 100%;
                   
                }
                ._time{
                    // text-align: left;
                    display: none;
                        span{
                            font-size: 18px;
                            order: 2;
                        }
                        p{
                            font-size: 16px;
                        }
                    }
                    ._time1{
                        margin-top: 5px;
                    }
                }

                ._l_b{
                    ._ll p span{
                        font-size: 16px;
                    }
                }



            }
        }
    }
}
</style>