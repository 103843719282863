<template>
  <div class="_wrap">
    <div class="_banner">
      <!-- <img src="@/assets/image/home-img/com_home_banner.png" alt="八路军驻洛办事处纪念馆-官方网站"> -->
      <img :src="$IMG_URL + $store.state.webData.top_image.sea_list_image" alt="八路军驻洛办事处纪念馆-官方网站">
    </div>

    <div class="_tabs">
      <div class="_auto">
        <div class="_tit">社教</div>
        <div class="_list">
          <div :class="{ _li: true, _active: current == index }" v-for="(item, index) in tabList" :key="index"
            @click="changeTab(item, index)">{{ item.name }}</div>
        </div>
      </div>

    </div>

    <div class="_auto">
      <div class="_bread">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>社教</el-breadcrumb-item>
          <el-breadcrumb-item>{{ breadTit }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>


      <div class="_allList">
        <!-- 社教活动 -->
        <transition>
          <div v-if="current == 0">
            <!-- <noticeList ref="noticeListRef"></noticeList> -->
            <sjhd ref="sjhdRef" :list="seaList"></sjhd>
          </div>
        </transition>


        <!-- 实景党课 -->
        <transition>

          <div v-if="current == 1">

            <sjdk ref="sjdkRef" :list="partyList"></sjdk>
          </div>
        </transition>

        <!-- 志愿者 -->
        <transition>

          <div v-if="current == 2">

            <zyz ref="zyzRef" :list="volunList" @zyzChange="zyzChange"></zyz>
          </div>
        </transition>

      </div>


      <div class="_page" v-show="flag">
        <pagination v-show="total > 0" :total="total" :page.sync="queryParams.page" :limit.sync="queryParams.page_num"
          @pagination="getList" />

      </div>

    </div>




    <mainFooter ref="mainFooterRef"></mainFooter>

  </div>
</template>
  
<script>
import { getSEA, partyClass, getAd } from "@/api/getData"
import sjhd from './model/sjhd.vue'
import sjdk from './model/sjdk.vue'
import zyz from './model/zyz.vue'
export default {
  components: {
    sjhd,
    sjdk,
    zyz

  },
  data() {
    return {
      wbTitle: "八路军驻洛办事处纪念馆-社教",
      keywords: "八路军驻洛办事处纪念馆,洛八办",
      desc: "八路军驻洛办事处纪念馆",
      flag: true,
      breadTit: "社教活动",
      total: 0,
      seaList: [],
      partyList: [],
      volunList: [],
      current: 0,
      queryParams: {
        page: 1,
        page_num: 10,
      },
      tabList: [
        {
          name: "社教活动",
        },
        {
          name: "实景党课",
        }, {
          name: "志愿者"
        }
      ],
    }
  },
  // 动态设置页面标题、关键字和描述等属性  
  // metaInfo() {

  //   return {
  //     title: this.wbTitle,
  //     meta: [
  //       { name: "keywords", content: this.keywords },
  //       { name: "description", content: this.desc },
  //     ]
  //   }
  // },
  created() {
    let title = this.$route.query.tit
    let subtit = this.$route.query.subtit
    this.breadTit = title
    if (title) {
      if (title == '社教活动') {
        this.current = 0
      } else if (title == '实景党课') {
        this.current = 1
      } else if(title == '志愿者') {
        this.current = 2
        if(subtit == '志愿者风采'){
          this.flag = true
        }else{
          this.flag = false
        }

      }
    }
    this.getList()


  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to, from) {
      console.log(to);
      let title = to.query.tit
      if (title) {
        if (title == '社教活动') {
          this.current = 0
        } else if (title == '实景党课') {
          this.current = 1
        } else {
          this.current = 2

        }
      }
    }
  },


  mounted() {
    // this.$scrollToSection("_asd")


  },
  methods: {
    changeTab(item, index) {
      this.current = index
      this.breadTit = item.name
      this.queryParams.page = 1
      this.flag = true
      this.getList()
    },
    getList() {
      switch (this.current) {
        case 0:
          this.getSEA()
          break;
        case 1:
          this.partyClass()
          break;
        case 2:
          this.getAd()
          break;
      }
    },
    // 社教活动
    getSEA() {
      getSEA(this.queryParams).then(res => {
        console.log(res)
        this.seaList = res.data.data
        this.total = res.data.total

      })
    },

    // 实景党课
    partyClass() {
      partyClass(this.queryParams).then(res => {
        console.log(res)
        this.partyList = res.data.data
        this.total = res.data.total
      })
    },

    // 志愿者
    getAd() {
      getAd(this.queryParams).then(res => {
        console.log(res)
        this.volunList = res.data.data
        this.total = res.data.total

      })
    },
    zyzChange(index) {
      console.log(index)
      if (index) {
        this.flag = false
      } else {
        this.flag = true
      }
    }

  }

}
</script>
  
<style lang="scss" scoped>
@import "../../common/mixin.scss";

._page
{
  text-align: center;
  margin-bottom: 65px;
  margin-top: 25px;
}

._wrap
{
  width: 100%;
  overflow: hidden;
  background-color: #FDFAF6;
}

._banner
{
  width: 100%;
  height: 560px;

  img
  {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


}

._allList
{
  margin-top: 50px;
}

._auto
{
  width: 75%;
  margin: 0 auto;
}

._tabs
{
  width: 100%;
  background-color: #fff;

  // padding: 0px 0;
  ._auto
  {
    display: flex;

  }

  ._tit
  {
    margin-right: 40px;
    color: white;
    // background-color: #B41E1E;
    background-image: url("../../assets/image/information-img/com_bg_title.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // padding: 20px 40px;
    width: 183px;
    height: 90px;
    text-align: center;
    line-height: 90px;
    font-size: 30px;
  }

  ._list
  {
    display: flex;
    align-items: center;

    ._li
    {
      margin: 0 20px;
      color: #333;
      font-weight: bold;
      font-size: 24px;
      padding: 25px 0;
      cursor: pointer;
    }

    ._active
    {
      color: #B41E1E;
      border-bottom: 4px solid #B41E1E;

    }

    // ._active::after{
    //   content: "";
    //   display: inline-block;
    //   width: ;
    // }
  }
}

._bread
{
  margin: 40px 0 20px;
}

::v-deep .el-breadcrumb__inner
{
  color: #969696 !important;
}





.v-enter-active,
.v-leave-active
{
  transition: all .8s ease;
}

.v-enter,
.v-leave-to
{
  transform: translateX(100px);
  opacity: 0;
}




@include respondTo('phone'){

  ._banner{
    height: 250px;
  }

  ._page{
    margin-bottom: 40px;
  }
  ._auto{
    padding: 0 8px;
  }
  ._tabs{
    ._auto{
      padding: 0 8px;
      display: block;
      ._tit{
        width: 140px;
        height: 60px;
        line-height: 60px;
        margin-right: 0;
      }

      ._list{
     
        justify-content: space-between;
        ._li{
          margin: 0;
          font-size: 22px;
          padding: 20px 0;
        }
      }
    }
  }


  ._bread{
    margin: 20px 0 0;
  }

  ._allList{
    margin-top: 0;
  }
}
</style>