<template>
  <div class="_wrap">
    <div class="_auto">
      <div class="_tabs">
        <div
          :class="{ _li: true, _active: current == index }"
          v-for="(item, index) in tabList"
          :key="index"
          @click="tabChange(item, index)"
        >
          {{ item.name }}
        </div>
      </div>

      <transition>
        <div class="_cont" v-show="current == 0">
          <div class="_item" v-for="(item, index) in list" :key="index">
            <img
              :src="$IMG_URL + item.image"
              alt="八路军驻洛办事处纪念馆-官方网站"
            />
          </div>
        </div>
      </transition>

      <div>
        <transition>
          <div class="_zm" v-show="current == 1">
            <div v-if="content.length > 0" class="_zmcont" v-html="content"></div>
            <div class="_noData" v-else>暂无数据...</div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { volunteer } from "@/api/getData";
export default {
  props: {
    list: {
      type: Array,
    },
  },
  data() {
    return {
      current: 0,
      content: "",
      tabList: [
        {
          name: "志愿者风采",
        },
        {
          name: "志愿者招募",
        },
      ],
    };
  },
  created() {
    let subtit = this.$route.query.subtit;
    console.log(subtit);
    if (subtit) {
      if (subtit == "志愿者风采") {
        this.current = 0;
      } else {
        this.current = 1;
      }
    } else {
      this.current = 0;
    }

    this.volunteer();
  },
  methods: {
    tabChange(item, index) {
      console.log(item);
      this.current = index;
      this.$emit("zyzChange", index);
    },
    volunteer() {
      volunteer().then((res) => {
        console.log(res);
        this.content = res.data.vr;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../common/mixin.scss";

._noData {
  text-align: center;
  font-size: 20px;
}

._wrap {
  width: 100%;

  ._auto {
    ._tabs {
      display: flex;
      align-items: center;
      justify-content: center;

      ._li {
        padding: 0 53px;
        color: #999;
        font-size: 24px;
        position: relative;
        cursor: pointer;
      }

      ._active {
        color: #333;

        position: relative;
      }

      ._active::before {
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        border: 1px solid #b41e1e;
        transform: rotate(45deg);
        position: absolute;
        top: 14px;
        left: 30px;
      }

      ._li:nth-child(1)::after {
        content: "";
        width: 1px;
        height: 18px;
        background-color: #cdcdcd;
        position: absolute;
        right: 0;
        top: 10px;
      }
    }

    ._cont {
      display: flex;
      flex-wrap: wrap;
      margin-top: 60px;

      ._item {
        width: 32%;
        cursor: pointer;

        margin-right: 2%;
        margin-bottom: 60px;
        overflow: hidden;

        img {
          // width: 463px;
          width: 100%;
          height: 316px;
          object-fit: cover;
          transition: all 0.5s;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }
      }

      ._item:hover img {
        transform: scale(1.1);
      }
    }

    ._zm {
      margin-top: 60px;
      margin-bottom: 100px;
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: all 0.8s ease;
}

.v-enter,
.v-leave-to {
  transform: translateX(100px);
  opacity: 0;
}

@include respondTo("phone") {

  ._wrap {
    ._auto {
      padding: 0;
      ._tabs {
        margin-top: 20px;
        ._li {
          font-size: 22px;
          padding: 0 20px;
        }
        ._li::before{
            top: 10px;
            left: 8px;
        }
        ._li::after{
            top: 10px;
            right: 8px;
        }
      }

      ._cont{
        margin-top: 20px;
        justify-content: space-between;
        ._item{
            width: 49%;
            margin-right: 0;
            margin-bottom: 20px;
            img{
                height: 146px;
            }

        }
      }

      ._zm{
        margin-top: 20px;
        margin-bottom: 40px;

        ._zmcont{
            width: 100%;

        }
      }
    }
  }
}
</style>